import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import StockSummary from "./StockSummary";
import Cuts from "./Cuts";
import "./styles/ProjectPage.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

const ProjectPage = () => {
  const [projectData, setProjectData] = useState(null);
  const [selectedWidth, setSelectedWidth] = useState("total");
  const [activeTab, setActiveTab] = useState("stockSummary");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjectData = async () => {
      const token = localStorage.getItem("jwtToken");
      if (!token) {
        setError("Unauthorized. Please log in.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${API_BASE_URL}/api/dashboard/get_project_stock_summary?project_id=${projectId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          setProjectData(data);
          setSelectedWidth("total");
        } else {
          throw new Error(data.message || "Failed to fetch project data");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [projectId]);

  if (loading) return <div className="loading-container">Loading...</div>;
  if (error) return <div className="error-container">{error}</div>;
  if (!projectData || !projectData.project_summary)
    return <div className="loading-container">No project data available</div>;

  return (
    <div className="project-container">
      <button onClick={() => navigate("/dashboard")} className="back-button">
        <ArrowLeft className="w-6 h-6" />
        Back
      </button>

      <div className="project-header">
        <h1 className="project-title">My Projects</h1>
        <div className="tab-container">
          <button
            className={`tab ${activeTab === "stockSummary" ? "active" : ""}`}
            onClick={() => setActiveTab("stockSummary")}
          >
            Stock Summary
          </button>
          <button
            className={`tab ${activeTab === "cuts" ? "active" : ""}`}
            onClick={() => setActiveTab("cuts")}
          >
            Cuts
          </button>
        </div>
      </div>

      {activeTab === "stockSummary" && (
        <StockSummary
          projectData={projectData}
          selectedWidth={selectedWidth}
          setSelectedWidth={setSelectedWidth}
        />
      )}
      {activeTab === "cuts" && <Cuts />}
    </div>
  );
};

export default ProjectPage;
