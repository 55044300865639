import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/ForgotPassword.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        // Call the backend API
        fetch(`${API_BASE_URL}/api/forgot_password`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.message === "success") {
              setMessage(`A password recovery email has been sent to ${email}`);
              // Delay redirect to give user time to see the message
              setTimeout(() => {
                navigate("/");
              }, 3000); // Redirect to login after 3 seconds
            } else {
              setMessage("Failed to send recovery email. Please try again.");
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setMessage("An error occurred. Please try again later.");
          });
    };

    return (
        <div className="forgot-password-container">
            <form className="forgot-password-form" onSubmit={handleSubmit}>
                <h2>Forgot Password</h2>
                {message && <p style={{ color: 'green' }}>{message}</p>}
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <button type="submit">Send Recovery Email</button>
            </form>
        </div>
    );
}

export default ForgotPassword;
