import React, { useState } from "react";
import "./styles/Login.css"; // We will style the form in a separate CSS file
import { useNavigate } from "react-router-dom";
import logo from "./assets/logo.png";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Clear previous errors
    setError("");

    // Make sure payload has `email` and `password` keys
    fetch(`${API_BASE_URL}/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }), // Payload with correct keys
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "success") {
          const token = data.data.token;
          localStorage.setItem("jwtToken", token); // Store JWT token in local storage
          console.log("Login successful");
          // Redirect to the dashboard or another page
          window.location.href = "/dashboard";
        } else {
          setError("Invalid credentials. Please try again.");
          console.log("Login failed");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("An error occurred. Please try again later.");
      });
  };

  return (
    <div className="login-background">
      <div className="login-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <img src={logo} alt="App Logo" className="login-logo" />
          <h2>LOGIN</h2>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="extra-options">
            <label>
              <input type="checkbox" /> Remember me
            </label>
            <a href="#" onClick={() => navigate("/forgot-password")}>
              Forgot?
            </a>
          </div>
          <button type="submit">LOGIN</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
