import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MenuItem,
  TextField,
  Select,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  CircularProgress,
} from "@mui/material";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

const Dashboard = () => {
  const [metrics, setMetrics] = useState({
    total_projects: 0,
    total_waste_percent: 0,
    metric_tonnes_processed: 0,
    total_patterns_generate: 0,
    metric_tonnes_waste_generated: 0,
    carbon_reduction: 0,
  });
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [timeLimit, setTimeLimit] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newProject, setNewProject] = useState({
    name: "",
    project_client_name: "",
    location: "",
    expected_due_date: "",
    uploaded_files: null,
  });

  const navigate = useNavigate();

  // Pagination States
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page changes
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileUpload = (e) => {
    setNewProject((prev) => ({
      ...prev,
      uploaded_files: e.target.files[0],
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setNewProject({
      name: "",
      project_client_name: "",
      location: "",
      expected_due_date: "",
      uploaded_files: null,
    });
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("jwtToken");
    if (!token) {
      setError("Unauthorized. Please log in.");
      return;
    }

    const formData = new FormData();
    Object.entries(newProject).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/dashboard/create_new_project`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create a new project");
      }

      // Refresh dashboard data after successful submission
      fetchDashboardData();
      handleModalClose();
    } catch (error) {
      console.error("Error creating new project:", error);
      setError(error.message || "An error occurred.");
    }
  };

  const fetchDashboardData = async () => {
    const token = localStorage.getItem("jwtToken");
    if (!token) {
      setError("Unauthorized. Please log in.");
      setLoading(false);
      return;
    }

    try {
      const metricsResponse = await fetch(
        `${API_BASE_URL}/api/dashboard/get_dashboard_details?time_limit=${timeLimit}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const metricsResult = await metricsResponse.json();
      if (metricsResponse.ok) {
        const receivedMetrics = metricsResult.data || metricsResult;
        setMetrics((prevMetrics) => ({
          ...prevMetrics,
          ...receivedMetrics,
        }));
      } else {
        throw new Error(metricsResult.message || "Failed to fetch metrics");
      }

      const projectsResponse = await fetch(
        `${API_BASE_URL}/api/dashboard/get_projects?time_limit=${timeLimit}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const projectsResult = await projectsResponse.json();
      if (projectsResponse.ok) {
        const receivedProjects = projectsResult.data || projectsResult;
        setProjects(Array.isArray(receivedProjects) ? receivedProjects : []);
      } else {
        throw new Error(projectsResult.message || "Failed to fetch projects");
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setError(error.message || "An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
    setPage(0);
  }, [timeLimit]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = projects.filter((project) =>
        project.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProjects(filtered);
      setPage(0);
    } else {
      setFilteredProjects(projects);
    }
  }, [searchTerm, projects]);

  const metricsCards = [
    {
      title: "Total Projects",
      value: metrics?.total_projects || 0,
    },
    {
      title: "Waste Percentage",
      value: `${metrics?.total_waste_percent || 0} %`,
    },
    {
      title: "Metric Tonnes Processed",
      value: metrics?.metric_tonnes_processed || 0,
    },
    {
      title: "Patterns Generated",
      value: metrics?.total_patterns_generate || 0,
    },
    {
      title: "Waste Generated",
      value: `${metrics?.metric_tonnes_waste_generated || 0} MT`,
    },
    {
      title: "Carbon Reduction",
      value: `${metrics?.carbon_reduction || 0} %`,
    },
  ];

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 m-8 bg-red-200 rounded text-red-900 text-center">
        {error}
      </div>
    );
  }

  return (
    <div className="w-full mx-auto">
      <h2 className="text-4xl text-gray-700 font-semibold mb-8">
        Dashboard Overview
      </h2>

      <div className="flex flex-col justify-center gap-6">
        <div className="grid md:grid-cols-3 lg:grid-cols-6 sm:grid-cols-2 grid-cols-1 gap-4">
          {metricsCards.map((metric, index) => (
            <Paper key={index} elevation={2} className="p-3">
              <h3 className="text-base text-gray-500 font-medium">
                {metric.title}
              </h3>
              <p className="text-2xl font-medium text-gray-700">
                {metric.value}
              </p>
            </Paper>
          ))}
        </div>

        <Divider />

        <div>
          <h3 className="text-2xl text-gray-700 font-semibold mb-4">
            Recent Projects
          </h3>

          <div className="flex justify-between items-center gap-2 mb-3">
            <TextField
              label="Search Projects"
              variant="outlined"
              value={searchTerm}
              size="small"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="flex items-center gap-2">
              <Select
                size="small"
                // label="Filter Date/Time Range"
                autoWidth
                displayEmpty
                value={timeLimit}
                onChange={(e) => setTimeLimit(e.target.value)}
              >
                <MenuItem value="">All Time</MenuItem>
                <MenuItem value="1">Last Day</MenuItem>
                <MenuItem value="7">Last 7 Days</MenuItem>
                <MenuItem value="30">Last 30 Days</MenuItem>
                <MenuItem value="365">Last 1 Year</MenuItem>
              </Select>

              <Button
                variant="contained"
                onClick={() => setIsModalOpen(true)}
                sx={{ textTransform: "none", height: "40px" }}
              >
                + Create New Project
              </Button>
            </div>
          </div>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "600" }}>Project Name</TableCell>
                  <TableCell sx={{ fontWeight: "600" }}>No of Files</TableCell>
                  <TableCell sx={{ fontWeight: "600" }}>Created On</TableCell>
                  <TableCell sx={{ fontWeight: "600" }}>Due Date</TableCell>
                  <TableCell sx={{ fontWeight: "600" }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredProjects.length > 0 ? (
                  filteredProjects
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((project, index) => {
                      const isClickable =
                        project.status === "available for download";
                      const statusColor =
                        project.status === "submitted for processing"
                          ? "gray"
                          : "green";

                      return (
                        <TableRow
                          key={index}
                          onClick={() =>
                            isClickable && navigate(`/project/${project.id}`)
                          }
                          sx={{
                            cursor: isClickable ? "pointer" : "",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.04)",
                            },
                          }}
                        >
                          <TableCell>{project.name || "N/A"}</TableCell>
                          <TableCell>{project.number_of_files || 0}</TableCell>
                          <TableCell>
                            {project.created_at
                              ? new Date(
                                  project.created_at
                                ).toLocaleDateString()
                              : "N/A"}
                          </TableCell>
                          <TableCell>
                            {project.expected_due_date
                              ? new Date(
                                  project.expected_due_date
                                ).toLocaleDateString()
                              : "N/A"}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: statusColor,
                            }}
                          >
                            {project.status || "N/A"}
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>No projects available</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredProjects.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Dialog open={isModalOpen} onClose={handleModalClose}>
            <DialogTitle sx={{ textAlign: "center", fontSize: "25px" }}>
              Create New Project
            </DialogTitle>

            <DialogContent>
              <TextField
                label="Name"
                name="name"
                fullWidth
                size="small"
                margin="dense"
                value={newProject.name}
                onChange={handleInputChange}
              />
              <TextField
                label="Project Client Name"
                name="project_client_name"
                fullWidth
                size="small"
                margin="dense"
                value={newProject.project_client_name}
                onChange={handleInputChange}
              />
              <TextField
                label="Location"
                name="location"
                fullWidth
                size="small"
                margin="dense"
                value={newProject.location}
                onChange={handleInputChange}
              />
              <TextField
                label="Expected Due Date"
                name="expected_due_date"
                type="date"
                fullWidth
                size="small"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                value={newProject.expected_due_date}
                onChange={handleInputChange}
              />

              <Input
                label="Upload File"
                name="uploaded_files"
                type="file"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                onChange={handleFileUpload}
                className="mt-1 before:!border-none after:!border-none"
              />
            </DialogContent>

            <DialogActions sx={{ marginBottom: "16px", marginRight: "16px" }}>
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
                sx={{ textTransform: "none" }}
              >
                Submit
              </Button>
              <Button
                onClick={handleModalClose}
                color="error"
                variant="contained"
                sx={{ textTransform: "none" }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
