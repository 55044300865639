import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./styles/Cuts.css";
import { CircularProgress } from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

const Cuts = () => {
  const [widthOptions, setWidthOptions] = useState([]);
  const [selectedWidth, setSelectedWidth] = useState("");
  const [cutsData, setCutsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { projectId } = useParams();

  useEffect(() => {
    fetchWidthOptions();
  }, []);

  useEffect(() => {
    if (selectedWidth) {
      fetchCutsForWidth(selectedWidth);
    }
  }, [selectedWidth]);

  const fetchWidthOptions = async () => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await fetch(
        `${API_BASE_URL}/api/dashboard/get_project_cuts?project_id=${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setWidthOptions(data);
        if (data.data.length > 0) {
          setSelectedWidth(data.data[0].width);
        }
      } else {
        throw new Error(data.message || "Failed to fetch width options");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchCutsForWidth = async (width) => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await fetch(
        `${API_BASE_URL}/api/dashboard/get_project_cuts_for_width?width=${width}&project_id=${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setCutsData(data);
      } else {
        throw new Error("Failed to fetch cuts data");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const renderCutPattern = (values) => {
    const segments = values.split("#");
    return (
      <div className="cut-pattern">
        {segments.map((value, index) => (
          <div
            key={index}
            className="cut-segment"
            style={{
              flex: parseInt(value),
              backgroundColor:
                index === segments.length - 1
                  ? "#333" // Apply dark grey color for offcut
                  : `hsl(${(index * 30) % 360}, 70%, 60%)`,
            }}
          >
            {value}
          </div>
        ))}
      </div>
    );
  };

  const handleDownloadPDF = async () => {
    const element = document.querySelector(".cuts-table");
    if (element) {
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 10, 10, pdfWidth, pdfHeight);
      pdf.save(`cuts_for_${selectedWidth}mm.pdf`);
    }
  };

  const handleMappingDataDownload = () => {
    const selectedData = widthOptions.data.find(
      (item) => item.width === selectedWidth
    );
    if (selectedData) {
      window.open(selectedData.file_url);
    }
  };

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 m-8 bg-red-200 rounded text-red-900 text-center">
        {error}
      </div>
    );
  }

  return (
    <div className="cuts-container">
      <div className="cuts-header">
        <div className="action-buttons">
          <button className="action-button" onClick={handleDownloadPDF}>
            Download as PDF
          </button>

          <button className="action-button" onClick={handleMappingDataDownload}>
            Mapping Data
          </button>

          <button
            className="action-button"
            onClick={() => window.open(widthOptions.mapping_summary_url)}
          >
            Mapping Summary
          </button>

          <button
            className="action-button"
            onClick={() => window.open(widthOptions.id_map_url)}
          >
            Mapping ID
          </button>

          <select
            value={selectedWidth}
            onChange={(e) => setSelectedWidth(e.target.value)}
            className="width-select"
          >
            {widthOptions.data.map((option) => (
              <option key={option.width} value={option.width}>
                {option.width}mm
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="cuts-table">
        <h2 className="text-lg font-semibold mb-4">
          Rebar Diameter: {selectedWidth}mm
        </h2>
        <table>
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Quantity</th>
              <th>Offcut</th>
              <th>Cuts pattern</th>
            </tr>
          </thead>
          <tbody>
            {cutsData.map((cut, index) =>
              cut.values_list.map((pattern, subIndex) => (
                <tr key={`${index}-${subIndex}`}>
                  <td>{subIndex + 1}</td>
                  <td>{cut.quantity_list[subIndex]}</td>
                  <td>
                    {pattern
                      ? pattern.split("#").pop() // Extract the last value from the pattern
                      : "-"}
                  </td>
                  <td>
                    <div className="pattern-cell">
                      <div className="pattern-text">{pattern}</div>
                      {renderCutPattern(pattern)}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Cuts;
