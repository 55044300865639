import React from 'react';
import { PieChart, Pie, Cell, Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';
import { ArrowLeft } from 'lucide-react';

const StockSummary = ({ projectData = {}, selectedWidth = 'total', setSelectedWidth }) => {
  const project_summary = projectData?.project_summary || [];
  const project_name = projectData?.name || 'N/A';
  
  const selectedSummary =
    project_summary.find((s) => s.width === selectedWidth) ||
    project_summary.find((s) => s.width === 'total') ||
    { waste_perc: 0, quantity: 0, unique_sizes: 0, wastage: 0, weight: 0 };

  const pieData = [
    { name: 'Utilized', value: 100 - (selectedSummary.waste_perc || 0) },
    { name: 'Waste', value: selectedSummary.waste_perc || 0 },
  ];

  const barData = project_summary
    .filter((item) => item.width !== 'total' && item.width && item.quantity)
    .map((item) => ({
      width: `${item.width}mm`,
      bars: item.quantity,
    }));

  return (
    <div className="project-container">

      {/* Project Title and Width Selector */}
      <div className="project-header">
        <h2 className="project-title">{project_name}</h2>
        <select
          value={selectedWidth}
          onChange={(e) => setSelectedWidth(e.target.value)}
          className="width-selector"
        >
          {project_summary.map((item) => (
            <option key={item.width || 'default'} value={item.width}>
              {item.width === 'total' ? 'Overall' : `${item.width}mm`}
            </option>
          ))}
        </select>
      </div>

      {/* Main Content */}
      <div className="content-grid">
        <div className="metrics-grid">
          {/* Left Column - Metrics */}
          <div className="summary-card">
            <h3 className="metric-title">
              {selectedWidth === 'total' ? 'Overall' : `${selectedWidth}mm`} (Pieces)
            </h3>
            <div className="metric-group">
              <div className="detail-item">
                <span className="detail-label">Total No. Bars : </span>
                <span className="detail-value">{selectedSummary.quantity || 0}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Total(#unique sizes) : </span>
                <span className="detail-value">{selectedSummary.unique_sizes || 0}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Wastage : </span>
                <span className="detail-value">{selectedSummary.waste_perc || 0}%</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Weight In Tonnes : </span>
                <span className="detail-value">{selectedSummary.weight || 0}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Wastage In Tonnes : </span>
                <span className="detail-value">{selectedSummary.wastage || 0}</span>
              </div>
            </div>
          </div>

          {/* Right Column - Project Details */}
          <div className="summary-card">
            <h3 className="metric-title">Project details</h3>
            <div className="metric-group">
              <div className="detail-item">
                <span className="detail-label">Client Name : </span>
                <span className="detail-value">{projectData?.project_client_name || 'N/A'}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label"># of files : </span>
                <span className="detail-value">{projectData?.number_of_files || 0}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Location : </span>
                <span className="detail-value">{projectData?.location || 'N/A'}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Status : </span>
                <span className="detail-value">{projectData?.status || 'N/A'}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Due Date : </span>
                <span className="detail-value">{projectData?.expected_due_date || 'N/A'}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Charts Section */}
        <div className="charts-container">
          <div className="chart-container">
            <h3 className="chart-title">Stock summary</h3>
            {/* Pie Chart */}
            <div className="pie-chart-container">
              <PieChart width={300} height={300}>
                <Pie
                  data={pieData}
                  cx={150}
                  cy={150}
                  innerRadius={60}
                  outerRadius={120}
                  dataKey="value"
                >
                  <Cell fill="#3B82F6" />
                  <Cell fill="#EF4444" />
                </Pie>
                <Tooltip />
              </PieChart>
            </div>
          </div>

          {/* Bar Chart */}
          <div className="bar-chart-container">
            <BarChart width={400} height={300} data={barData}>
              <CartesianGrid strokeDasharray="3 3" className="opacity-50" />
              <XAxis dataKey="width" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="bars" fill="#9CA3AF" />
            </BarChart>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockSummary;