import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  ListItemIcon,
} from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";

const Layout = ({ children }) => {
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    navigate("/");
  };

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <AppBar position="static">
        <Toolbar className="flex justify-between">
          <h2 className="text-2xl font-semibold">b2boptimize.ai</h2>

          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={(e) => setMenuAnchor(e.currentTarget)}
          >
            <Avatar src="/broken-image.jpg" />
          </IconButton>
          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={() => setMenuAnchor(null)}
          >
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Log out
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <div className="flex-1 py-4 px-8 m-8 box-border">{children}</div>
    </div>
  );
};

export default Layout;
